//@ts-nocheck
import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  FormCheck,
  OverlayTrigger,
  Tooltip,
  Image,
} from 'react-bootstrap';
import {
  CheckCircleIcon,
  DotsHorizontalIcon,
  EyeIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
  UserRemoveIcon,
  XCircleIcon,
} from '@heroicons/react/solid';

export interface ToggleActionProps {
  status: string;
  onTogglePress?(key: string): void;
}

export const ToggleAction = (props: ToggleActionProps) => {
  const { status, onTogglePress } = props;

  const onToggleClick = (key: string) => {
    onTogglePress && onTogglePress(key);
  };
  const _getToggleItems = () => {
    console.log('status', status);
    if (status === 'created' || status === 'invited' || status === 'verified') {
      return (
        <Dropdown.Item
          className="d-flex align-items-center"
          onClick={() => onToggleClick('re-invite')}
        >
          <CheckCircleIcon className="dropdown-icon text-gray-400 me-2" />
          Reinvite
        </Dropdown.Item>
      );
    } else if (status === 'active') {
      return (
        <Dropdown.Item
          className="d-flex align-items-center"
          onClick={() => onToggleClick('suspend')}
        >
          <ShieldExclamationIcon className="dropdown-icon text-gray-400 me-2" />
          Suspend
        </Dropdown.Item>
      );
    } else if (status === 'suspended') {
      return (
        <Dropdown.Item
          className="d-flex align-items-center"
          onClick={() => onToggleClick('activate')}
        >
          <ShieldExclamationIcon className="dropdown-icon text-gray-400 me-2" />
          Activate
        </Dropdown.Item>
      );
    }
  };

  return (
    <Dropdown as={ButtonGroup}>
      <Dropdown.Toggle
        as={Button}
        split
        variant="link"
        className="text-dark m-0 p-0"
      >
        <DotsHorizontalIcon className="icon icon-xs" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
        <Dropdown.Item
          className="d-flex align-items-center"
          //onClick={() => onToggleClick('view')}
        >
          <EyeIcon className="dropdown-icon text-gray-400 me-2" />
          View
        </Dropdown.Item>
        {_getToggleItems()}
      </Dropdown.Menu>
    </Dropdown>
  );
};
