import { VApplication } from '@/application';
import { DistillersPageService } from '../../../services';

const MODULE_NAME = 'profile.machines.states.createDistiller';

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync('services.DistillersPageService') as DistillersPageService;
};

export const createDistiller = async (context: any, event: any) => {
  const currentState = 'ADD_CREDIT_UNION';
  const method = 'createDistiller';

  return new Promise(async (resolve, reject) => {
    try {
      const srv = getSrv();
      const content = await srv.createDistiller(event?.payload);
      console.log('createDistiller content =>', content);
      if (content) {
        return resolve({
          key: 'ADD_CREDIT_UNION_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'add Credit union failed';
        return reject({
          key: 'ADD_CREDIT_UNION_FAILED',
          value: msg,
        });
      }
    } catch (err: any) {
      let _msg = err?.message?.replace('GraphQL error:', '');
      return reject({
        key: 'ADD_CREDIT_UNION_FAILED',
        value: _msg,
      });
    }
  });
};
